import request from "@/utils/request";

// 采购开单
export function purchaseOrderCreate(data) {
  return request({ url: `/purchase_orders/`, method: "post", data });
}

export function purchaseOrderList(params) {
  return request({ url: `/purchase_orders/`, method: "get", params });
}

export function purchaseOrderUpdate(data) {
  return request({ url: `/purchase_orders/${data.id}/`, method: "put", data });
}

export function purchaseOrderDestroy(data) {
  return request({ url: `/purchase_orders/${data.id}/`, method: "delete", data });
}

export function purchaseOrderDetail(params) {
  return request({ url: `/purchase_orders/${params.id}/`, method: "get", params });
}

export function purchaseOrdersCommit(data) {
  return request({ url: `/purchase_orders/${data.id}/commit/`, method: "post", data });
}

export function purchaseOrdersSubmit(data) {
  return request({ url: `/purchase_orders/${data.id}/submit/`, method: "post", data });
}

export function purchaseOrdersCancelSubmit(data) {
  return request({ url: `/purchase_orders/${data.id}/cancel_submit/`, method: "post", data });
}

export function purchaseOrdersVoid(data) {
  return request({ url: `/purchase_orders/${data.id}/void/`, method: "post", data });
}

export function purchaseFileUpload(data) {
  return request({
    url: "/purchase_files/",
    method: "post",
    data,
  });
}

// 采购退货单
export function purchaseReturnOrderCreate(data) {
  return request({ url: `/purchase_return_orders/`, method: "post", data });
}

// 退货记录
export function purchaseReturnOrdersList(params) {
  return request({ url: `/purchase_return_orders/`, method: "get", params });
}

export function purchaseReturnOrderUpdate(data) {
  return request({ url: `/purchase_return_orders/${data.id}/`, method: "put", data });
}

export function purchaseReturnOrderDestroy(data) {
  return request({ url: `/purchase_return_orders/${data.id}/`, method: "delete", data });
}

export function purchaseReturnOrdersCommit(data) {
  return request({ url: `/purchase_return_orders/${data.id}/commit/`, method: "post", data });
}

export function purchaseReturnOrdersSubmit(data) {
  return request({ url: `/purchase_return_orders/${data.id}/submit/`, method: "post", data });
}

export function purchaseReturnOrdersCancelSubmit(data) {
  return request({ url: `/purchase_return_orders/${data.id}/cancel_submit/`, method: "post", data });
}

// 退货记录详情
export function purchaseReturnOrderDetail(params) {
  return request({ url: `/purchase_return_orders/${params.id}/`, method: "get", params });
}

// 退货记录作废
export function purchaseReturnOrdersVoid(data) {
  return request({ url: `/purchase_return_orders/${data.id}/void/`, method: "post", data });
}

export function purchaseReturnFileUpload(data) {
  return request({
    url: "/purchase_return_files/",
    method: "post",
    data,
  });
}
